<template>
  <div>
    <Banner>
      <img style="width:501px;height:367px;bottom:61px" src="@/assets/image/productCenter/eSeal-banner-bg.png" alt="电子签章系统">
      <template v-slot:text>
        <div class="title">电子签章系统</div>
        <div class="hint-multi">
          电子签章系统由支持PKI技术并支持安全算法的硬件和支持各种应用的软件组成，我们提供相应的软硬件一体化服务。电子签章系统将传统印章图片与可靠的电子签名技术完美结合，在电子文档中实现可视化电子签名的专用产品。该产品以电子化的签章代替传统的纸质签字盖章流程，帮助客户真正实现无纸化应用，可有效确认电子文档来源、确保文档的完整性、防止对文档未经授权的篡改、确保签名行为的不可否认。
        </div>
        <el-button @click="$bus.$emit('cgContactFormShow', true)" type="primary">产品咨询</el-button>
      </template>
    </Banner>
    <Functional :list="functional" />
    <Features :list="features" />
    <LearnMore />
  </div>
</template>

<script>
import Functional from './components/Functional2.vue'
import Features from './components/Features.vue'
import LearnMore from './components/LearnMore.vue'
export default {
  components: { Features, LearnMore, Functional },
  data () {
    return {
      functional: [
        {
          icon: 'functional2-icon1.png',
          title: '电子签章',
          intro:
            '可靠合规，具有法律效力的电子签章服务、批量电子签章服务、骑缝电子签章服务',
          image: 'functional2-img1.png'
        },
        {
          icon: 'functional2-icon2.png',
          title: '印章管理',
          intro: '印模和印章的制作、停用、启用、注销等',
          image: 'functional2-img2.png'
        },
        {
          icon: 'functional2-icon3.png',
          title: '证书管理',
          intro: 'Ukey证书生命周期管理、加密卡证书发放、加密卡证书自动续期',
          image: 'functional2-img3.png'
        },
        {
          icon: 'functional2-icon4.png',
          title: '模版管理',
          intro: '模板上传、模板编辑；利用模板进行签署',
          image: 'functional2-img4.png'
        },
        {
          icon: 'functional2-icon5.png',
          title: '云端监控',
          intro: '监控硬件的状态，包括密钥对、内存、在线管理等',
          image: 'functional2-img5.png'
        }
      ],
      features: [
        {
          title: '易用',
          intro:
            '人性化的设计，有大量可选配和扩充的应用功能，无障碍地轻松学习和使用。'
        },
        {
          title: '功能丰富',
          intro:
            '支持电子印章、手写签名、批量盖章、批量验证、文档锁定和强安全控制、打印份数控制、电子表单等功能。'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.functional {
  padding: 160px 0;
  .title {
    font-size: 36px;
    color: #303b50;
    line-height: 48px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 80px;
  }
}
</style>